import {Component, OnInit} from "@angular/core";
import {PageBase} from "../../pagebase/pagebase";

@Component({
  selector: 'app-daywise-tracking',
  templateUrl: './daywise_tracking.component.html',
  styleUrls: ['../../pagebase/pagebase.scss']
})


export class DayWiseTrackingListComponent extends PageBase implements OnInit {

  ngOnInit(): void {

    this.sharedService.setHeader('DayWise Tracking');
    this.gridOptions.rowGroupPanelShow = 'never';
    this.showableFilters.region = true;
    this.showableFilters.territory = true;
    this.showableFilters.salesman = true;
    this.showableFilters.team = true;

    this.showViewSelector = true;
    this.showMapView = true;

    this.configureGrid();
    this.loadServerData();
  }


  configureGrid() {
    this.API_URL = '/api/retail_so_track_logs/get_daywise_tracks_list';
    this.columnDefs = [
      {headerName: "Track ID", field: "id", checkboxSelection: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true},
      {headerName: "Track", field: "name"},
      {headerName: "Device ID", field: "device_id"},
      {headerName: "Date", field: "create_date"},
      {headerName: "Employee Code", field: "emp_code"},
      {headerName: "Employee", field: "user"},
      {headerName: "Role", field: "role"},
      {headerName: "Beat", field: "beat"},
      {
        headerName: "Distance(Km)", field: "distance",
        valueGetter(params) {
          if (params.data) {
            if (params.data.distance && params.data.distance > 0) {
              return parseFloat((params.data.distance / 1000).toFixed(2));
            } else {
              return 0;
            }
          }
        }
      },
      {headerName: "Att Time", field: "att_time"},
      {headerName: "EOD Time", field: "eod_time"},
      {headerName: "TC", field: "tc"},
      {headerName: "PC", field: "pc"},
      {headerName: "VC", field: "vc"},
      {headerName: "Target", field: "target"},
      {
        headerName: "Achievement", field: "ach",
        valueGetter(params) {
          if (params.data) {
            if (params.data.ach) {
              return params.data.ach.toFixed(2);
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName: 'Progress', field: 'progress', minWidth: 180, menuTabs: [],
        valueGetter: params => {
          if (params.data) {
            if (params.data.target === null || parseInt(params.data.target, 10) === 0 || params.data.ach === null || parseInt(params.data.ach, 10) === 0) {
              return 0;
            } else {
              return Math.trunc((parseInt(params.data.ach, 10) / parseInt(params.data.target, 10)) * 100);
            }
          }
        },
        cellRenderer: params => {
          let displayElem = '<div class="row">\n' +
            '            <div class="progresscontainer1">\n' +
            '              <div class="progressinside1" style=" ';

          if (params.value > 50) {
            displayElem = displayElem + ' background-color: #379862;';
          } else if (params.value > 20) {
            displayElem = displayElem + ' background-color: #D97C0E;';
          } else {
            displayElem = displayElem + ' background-color: #E24260;';
          }
          let prog = params.value;
          if (params.value && parseFloat(params.value) > 100) {
            prog = 100;
          }
          displayElem = displayElem + 'width:' + prog + '%;"></div>\n' +
            '            </div>\n' +
            '            <div class="progresstext1" style="';

          if (params.value > 50) {
            displayElem = displayElem + ' color: #379862;';
          } else if (params.value > 20) {
            displayElem = displayElem + ' color: #D97C0E;';
          } else {
            displayElem = displayElem + ' color: #E24260;';
          }

          displayElem = displayElem + '">' + params.value + '%</div></div>';


          return displayElem;
        }
      }
    ];
  }

  onCellClicked(event) {

  }

  viewSelector(selectedview) {
    /*  if (this.gridOptions.api) {
        this.filteredData = this.gridApi.rowModel.rowsToDisplay;
      }*/
    if (this.gridOptions.columnApi) {
      this.columnState = this.gridColumnApi.getColumnState();
      this.gridColumnApi.setRowGroupColumns([]);
    }
    const rows = this.gridApi?.getSelectedRows();
    if (selectedview !== 'grid' && (!rows || rows.length === 0)) {
      this.toastr.error("No tracks Selected");
    } else {
      if (this.gridOptions.api) {
        this.filteredData = this.gridApi.getSelectedRows();
      } else {
        this.showApprove = false;
        this.filteredData = this.rowData;
      }
      if (selectedview === 'gallery') {
        this.viewGallery = true;

        this.viewGrid = false;
        this.viewMap = false;

      } else if (selectedview === 'map') {
        this.viewMap = true;
        this.viewGrid = false;
        this.viewGallery = false;
        this.showDate = false;
      } else {
        this.viewGrid = true;
        this.showDate = true;
        this.viewGallery = false;
        this.viewMap = false;
        if (this.showGalleryview) {
          this.showApprove = false;
        }

      }
    }

  }
}
