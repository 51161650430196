import {PageBase} from "../pagebase/pagebase";
import {Component, OnInit} from "@angular/core";
import {MatDialogConfig} from "@angular/material/dialog";
import {ErrorHandler} from "../error/error-handler";
import {RowNode} from "@ag-grid-enterprise/all-modules";
import {ErrortooltipComponent} from "../errortooltip";


@Component({
  selector: 'app-meet-list',
  templateUrl: '../pagebase/pagebase.html',
  styleUrls: ['../pagebase/pagebase.scss']
})

export class MeetListComponent extends PageBase implements OnInit, IPage {

  customFilter: any = {
    name: 'Meet Status',
    key: 'status',
    value: 'completed',
    values: [],
    show: true
  };

  matActionMenuItemDeclaration = []; //, feature: 'action_invoice_create'

  ngOnInit(): void {

    this.sharedService.setHeader('Meets');
    this.showUpload = false;
    this.customFilter.values = [
      {id: 'all', itemName: 'All'},
      {id: 'started', itemName: 'Started'},
      {id: 'completed', itemName: 'Completed'},
      {id: 'approved', itemName: 'Approved'},
      {id: 'pending', itemName: 'Pending'},
      {id: 'cancelled', itemName: 'Cancelled'},
      {id: 'scheduled', itemName: 'Scheduled'},
    ];

    this.MapViewMetaData = {
      id: 'id',
      title: 'name',
      latitude: 'lat',
      longitude: 'lng',
      color: '',
      field1: 'salesman',
      field2: 'type'
    };

    this.checkAndLoadPreviousSate();

    this.showCustomFilter = true;
    this.showActionButton = true;
    this.showDate = true;

    this.showViewSelector = true;
    this.showMapView = true;

    this.gridOptions.getRowStyle = this.getRowStyleFunc;
    this.frameworkComponents = {
      errortooltipComponent: ErrortooltipComponent
    };

    this.gridOptions.isRowSelectable = params => {
      if (params.data) {
        if (params.data.state === 'cancel') {
          return false;
        } else {
          return true;
        }
      }
    };
    this.getAuthorizedActions();
    this.checkAccessControl();
    this.configureGrid();
    this.loadServerData();
  }

  getRowStyleFunc(params) {
    if (params.data && params.data.id === 'TOTAL') {
      return {'font-weight': 'bold'};
    }
  }

  configureGrid() {
    const inThis = this;
    this.API_URL = '/api/partner_meets/get_meet_list_pwa';

    const OrderStatusCellClassRules = {
      'rag-green-outer': (params) => params.value === 'sale',
      'rag-amber-outer': (params) => params.value === 'confirmed',
      'rag-red-outer': (params) => params.value === 'cancel',
    };

    this.columnDefs = [
      {headerName: 'MEET ID', field: 'id', width: 80},
      {headerName: 'REGION', field: 'region', width: 80, hide: true},
      {headerName: 'STATE', field: 'state', width: 80, hide: true},
      {headerName: 'CLUSTER', field: 'cluster', width: 80},
      {headerName: 'TERRITORY', field: 'territory', width: 80, hide: true},
      {headerName: 'Name', field: 'name', width: 150},
      {headerName: 'PLANNED DATE', field: 'planned_date', width: 100},
      {headerName: 'START TIME', field: 'start_time', width: 100, valueGetter: params => this.formatTime(params.data.start_time)},
      {headerName: 'END TIME', field: 'end_time', width: 100, valueGetter: params => this.formatTime(params.data.end_time)},
      {
        headerName: 'TIME SPENT', field: 'time_spent', enableRowGroup: true, width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data.end_time && params.data.start_time) {
              const diff = new Date(params.data.end_time + ' UTC').getTime() - new Date(params.data.start_time + ' UTC').getTime();
              //return (diff / (1000 * 60)).toFixed(2);
              let msec = diff;
              const hh = Math.floor(msec / 1000 / 60 / 60);
              msec -= hh * 1000 * 60 * 60;
              const mm = Math.floor(msec / 1000 / 60);
              msec -= mm * 1000 * 60;
              const ss = Math.floor(msec / 1000);
              msec -= ss * 1000;

              return ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2);
            } else {
              return 0;
            }
          } else {
            return '';
          }
        }
      },
      {headerName: 'TYPE', field: 'type', width: 80},
      {headerName: 'SCODE', field: 'emp_code', width: 50},
      {headerName: 'SALESMAN', field: 'salesman', width: 140},
      {headerName: 'ROLE', field: 'role', width: 80},
      {headerName: 'TEAM', field: 'team', width: 80, hide: true},
      {headerName: 'ADDRESS', field: 'address', width: 150, valueGetter: this.configValueGetter('address')},
      {
        headerName: 'ADVANCE RECEIVED',
        field: 'adv_received',
        width: 100,
        valueGetter: this.configValueGetter('adv_received')
      },
      {headerName: 'NEXT ACTION PLAN', field: 'next_action_plan', width: 100, hide: true},
      {headerName: 'STATUS', field: 'status', width: 80}];

    this.getRowId = (params) => params.data.id;
  }


  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    const filteredRowData = this.gridApi.rowModel.rowsToDisplay;

    if (filteredRowData !== null && filteredRowData.length > 0) {
      const ids = [];
      filteredRowData.forEach(r => {
        ids.push(r.data.id);
      });
      localStorage.setItem('active_ids', JSON.stringify(ids));
      this.router.navigate([this.apiService.redirect_url + '/expense/meet_detail'], {
        relativeTo: this.route,
        queryParams: {id: rows[0].id}
      });
    }
  }

  checkAccessControl() {
    this.showCreate = true;
  }



  onMenuItemSelected(menuitem) {

  }

  configValueGetter(field) {
    return (params) => {
      if (params.data && params.data.config_json) {
        try {
          let config_json;
          if (params.data.config_json.constructor !== Object) {
            config_json = JSON.parse(params.data.config_json);
          } else {
            config_json = params.data.config_json;
          }
          if (config_json && config_json.hasOwnProperty(field)) {
            return config_json[field];
          }
        } catch (e) {
        }
      } else {
        return '';
      }
    };
  }


  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setColumnDefs(this.columnDefs);
    params.api.setRowData(this.rowData);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (params.api) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      this.gridOptions.api.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }

    setTimeout(() => {
      const pinnedBottomData = this.sharedService.generatePinnedBottomData(this.gridApi, this.gridColumnApi);
      this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);
  }

  onFilterChanged($event) {
    setTimeout(() => {
      const pinnedBottomData = this.generatePinnedBottomData();
      this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);
  }
}
