<div class="map" id="map1"
     style="margin-top: 10px;border: 1px solid #D7DDE6;border-radius: 5px;height: calc(100vh - 157px);">
  <div style="height: 100%;">
    <div class="col-md-12 pad0" style="height: 100%;">
      <div class="col-md-9 pr-0">
        <div style="height: calc(100vh - 160px);">
          <agm-map *ngIf="dataLoaded" (mapReady)="onMapReady($event)"
                   [zoom]="zoom" [styles]="styles">
            <div *ngIf="!hideAllMarkers">
              <ng-container *ngFor="let item of markerData; let i = index">
                <agm-marker *ngIf="item.lat && item.long && checkMarkerShow(item)" [latitude]="item.lat" [longitude]="item.long"
                            [iconUrl]="getMarker(item)" (mouseOver)="onMouseOver(infoWindow, $event)"
                            (mouseOut)="onMouseOut(infoWindow, $event)">
                  <agm-info-window #infoWindow
                                   [latitude]="item.lat"
                                   [longitude]="item.long">
                    <span *ngIf="item['icon'] === 'start_marker'" class="detailvaluebold2">Start</span>
                    <span *ngIf="item['icon'] === 'end_marker'" class="detailvaluebold2">End</span>
                    <span *ngIf="item.activity" class="detailvaluebold2">{{ item.activity }}</span>
                    <div *ngIf="item.activity" class="row centeralign" style="margin-top: 5px;">
                      <span class="detaillabel4">Type</span>
                      <span class="detailvalue3" style="margin-left: 5px;">{{ item.activity_type }}</span>

                      <span class="detaillabel4" style="margin-left: 20px;">Time</span>
                      <span class="detailvalue3" style="margin-left: 5px;">{{ item.time }}</span>
                    </div>
                    <div
                      *ngIf="!item.activity && (item['icon'] === 'start_marker' || item['icon'] === 'end_marker')"
                      class="row centeralign" style="margin-top: 5px;">
                      <span class="detailvalue3">{{ item.time }}</span>
                    </div>
                  </agm-info-window>
                </agm-marker>
              </ng-container>
            </div>
            <div *ngIf="showRetailerMarkers && !hideAllMarkers">
              <ng-container *ngFor="let item of retailerData; let i = index">
                <agm-marker *ngIf="item.lat && item.long && checkMarkerShow(item)" [latitude]="item.lat" [longitude]="item.long"
                            [iconUrl]="getMarker(item)" (mouseOver)="onMouseOver(infoWindow, $event)"
                            (mouseOut)="onMouseOut(infoWindow, $event)">
                  <agm-info-window #infoWindow
                                   [latitude]="item.lat"
                                   [longitude]="item.long">
                    <span class="detailvaluebold2">{{ item.ret_name }}</span>
                    <div class="row centeralign" style="margin-top: 5px;">
                      <span class="detaillabel4">Code</span>
                      <span class="detailvalue3" style="margin-left: 5px;">{{ item.ret_code }}</span>

                      <span class="detaillabel4" style="margin-left: 20px;">Type</span>
                      <span class="detailvalue3" style="margin-left: 5px;">{{ item.ret_type }}</span>
                    </div>
                  </agm-info-window>
                </agm-marker>
              </ng-container>
            </div>
            <div *ngIf="dayWiseTracks && dayWiseTracks.length > 0 && !hideAllTracks">
              <ng-container *ngFor="let day of dayWiseTracks; let i = index">
                <agm-polyline *ngIf="day.tracks && day.tracks.length > 0" [strokeColor]="getPolyLineColor(day)">
                  <ng-container *ngFor="let track of day.tracks">
                    <agm-polyline-point [latitude]="track.lat" [longitude]="track.long">
                    </agm-polyline-point>
                  </ng-container>
                </agm-polyline>
              </ng-container>
            </div>

          </agm-map>
        </div>

      </div>
      <div class="col-md-3 pl-0 pr-0">
        <div
          style="background: white;padding: 10px;height: calc(100vh - 160px); overflow-y: auto;overflow-x: hidden;">
          <div>
            <div>
              <mat-radio-group aria-label="last_retailer_option"
                               [(ngModel)]="colorMode" [ngModelOptions]="{standalone: true}">
                <mat-radio-button value="track">Color by Track</mat-radio-button>
                <mat-radio-button value="user">Color By User</mat-radio-button>
                <mat-radio-button value="beat">Color by Beat</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="mt-4" style="border-radius: 3px;padding: 10px;background: #F5F7F9;">
              <div class="row centeralign">
                <span class="detaillabel4">Total Users</span>
                <span class="detailvaluebold3" style="margin-left: auto">{{ this.userData.user_ids.length }}</span>
              </div>
              <div class="row centeralign" style="margin-top: 5px;">
                <span class="detaillabel4">Total Beats</span>
                <span class="detailvaluebold3" style="margin-left: auto">{{ totalBeats }}</span>
              </div>
              <div class="row centeralign" style="margin-top: 5px;">
                <span class="detaillabel4">Total Retailers</span>
                <span class="detailvaluebold3" style="margin-left: auto">{{ retailerData.length }}</span>
              </div>
            </div>
            <div class="mt-4">
              <div class="row centeralign" style="margin-bottom: 8px;">
                <img style="margin-right: 10px;height: 15px;" src="/assets/map/grey.svg">
                <span class="detaillabel2">Beat Jump</span>
                <div style="display: inline-block;margin-left: auto;">
                  <label class="switch">
                    <input
                      type="checkbox"
                      name="beatjump"
                      [(ngModel)]="showBeatJump"
                      #beatjump="ngModel"
                      [checked]="showBeatJump"
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
              <div class="row centeralign" style="margin-bottom: 8px;">
                <img style="margin-right: 10px;height: 15px;" src="/assets/map/blue.svg">
                <span class="detaillabel2">Visited</span>
                <div style="display: inline-block;margin-left: auto;">
                  <label class="switch">
                    <input
                      type="checkbox"
                      name="visited"
                      [(ngModel)]="showVisited"
                      #visited="ngModel"
                      [checked]="showVisited"
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
              <div class="row centeralign" style="margin-bottom: 8px;">
                <img style="margin-right: 10px;height: 15px;" src="/assets/map/warning_red.svg">
                <span class="detaillabel2">Not Visited</span>
                <div style="display: inline-block;margin-left: auto;">
                  <label class="switch">
                    <input
                      type="checkbox"
                      name="notvisited"
                      [(ngModel)]="showNotVisited"
                      #notvisited="ngModel"
                      [checked]="showNotVisited"
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
              <div class="row centeralign" style="margin-bottom: 8px;">
                <img style="margin-right: 10px;height: 15px;" src="/assets/map/green_tick.svg">
                <span class="detaillabel2">Completed</span>
                <div style="display: inline-block;margin-left: auto;">
                  <label class="switch">
                    <input
                      type="checkbox"
                      name="completed"
                      [(ngModel)]="showCompleted"
                      #completed="ngModel"
                      [checked]="showCompleted"
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
              <div class="row centeralign" style="margin-bottom: 8px;">
                <img style="margin-right: 10px;height: 15px;" src="/assets/map/warning_orange.svg">
                <span class="detaillabel2">Alert</span>
                <div style="display: inline-block;margin-left: auto;">
                  <label class="switch">
                    <input
                      type="checkbox"
                      name="showalrt"
                      [(ngModel)]="showAlert"
                      #showalrt="ngModel"
                      [checked]="showAlert"
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
              <div class="row centeralign" style="margin-bottom: 8px;">
                <img style="margin-right: 10px;height: 15px;" src="/assets/map/green.svg">
                <span class="detaillabel2">Productive</span>
                <div style="display: inline-block;margin-left: auto;">
                  <label class="switch">
                    <input
                      type="checkbox"
                      name="productive"
                      [(ngModel)]="showProductive"
                      #productive="ngModel"
                      [checked]="showProductive"
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
              <div class="row centeralign" style="margin-bottom: 8px;">
                <img style="margin-right: 10px;height: 15px;" src="/assets/map/start.svg">
                <span class="detaillabel2">Start</span>
                <div style="display: inline-block;margin-left: auto;">
                  <label class="switch">
                    <input
                      type="checkbox"
                      name="start"
                      [(ngModel)]="showStart"
                      #start="ngModel"
                      [checked]="showStart"
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
              <div class="row centeralign" style="margin-bottom: 8px;">
                <img style="margin-right: 10px;height: 15px;" src="/assets/map/end.svg">
                <span class="detaillabel2">End</span>
                <div style="display: inline-block;margin-left: auto;">
                  <label class="switch">
                    <input
                      type="checkbox"
                      name="end"
                      [(ngModel)]="showEnd"
                      #end="ngModel"
                      [checked]="showEnd"
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            <!--  <ng-container *ngFor="let item of legendData; let i = index">
                <div  class="row centeralign" style="margin-bottom: 8px;">
                  <img style="margin-right: 10px;height: 15px;" src="{{item.image}}">
                  <span class="detaillabel2">{{ item.name }}</span>
                  <div style="display: inline-block;margin-left: auto;">
                    <label class="switch">
                      <input
                        type="checkbox"
                        name="{{item.name}}"
                        [(ngModel)]="item.show"
                        #{{item.name}}="ngModel"
                        [checked]="item.show"
                      />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
              </ng-container>-->
            </div>
            <div class="mt-4">
              <div class="row centeralign" style="margin-bottom: 10px;">
                <span class="detaillabel2">Hide All Markers</span>
                <div style="display: inline-block;margin-left: auto;">
                  <label class="switch">
                    <input
                      type="checkbox"
                      name="hideallmarker"
                      [(ngModel)]="hideAllMarkers"
                      #hideallmarker="ngModel"
                      [checked]="hideAllMarkers"
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="mt-4">
              <div class="row centeralign" style="margin-bottom: 10px;">
                <span class="detaillabel2">Hide All Tracks</span>
                <div style="display: inline-block;margin-left: auto;">
                  <label class="switch">
                    <input
                      type="checkbox"
                      name="hidealltrack"
                      [(ngModel)]="hideAllTracks"
                      #hidealltrack="ngModel"
                      [checked]="hideAllTracks"
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
