import {Component, OnInit} from '@angular/core';
import {MatDialogConfig} from '@angular/material/dialog';

import {PageBase} from "../../pagebase/pagebase";
import {LocationPopupComponent} from "../../components/location_popup/location_popup.component";

@Component({
  selector: 'app-daily-user-tracking-component',
  templateUrl: '../../pagebase/pagebase.html',
  styleUrls: ['../../pagebase/pagebase.scss']
})

export class DailyUserTrackingComponent extends PageBase implements OnInit {

  apiData: any = [];

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Daily User Tracking');

    this.dateSelectMode = 'single';
    this.myDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.region = true;
    this.showableFilters.state = true;

    this.gridOptions.rowHeight = 100;
    this.gridOptions.autoGroupColumnDef = {
      headerName: 'CATEGORY',
      field: 'categ',
      minWidth: 150,
      cellRenderer: 'agGroupCellRenderer',
    };
    this.gridOptions.headerHeight = 40;
    this.gridOptions.defaultColDef.autoHeight = true;
    this.gridOptions.defaultColDef.wrapText = true;
    this.container = 'hr_attendance';
    this.configureGrid();
  }

  configureGrid() {

    const datepipe = this.datePipe;
    const inThis = this;
    this.API_URL = '/api/pwa_reports/dailyAttendanceReport';
    this.columnDefs = [
      {
        headerName: 'DATE', field: 'date', enableRowGroup: true,
        width: 80, menuTabs: []
      },
      {
        headerName: 'STATE', field: 'state',
        width: 80
      },
      {
        headerName: 'REGION', field: 'region',
        width: 80
      },
      {
        headerName: 'REGION IN CHARGE',
        field: 'region_manager',
        enableRowGroup: true,
        width: 100,
        resizable: true,
        hide: true,
      },
      {
        headerName: 'PARENT TEAM',
        field: 'parent_team',
        enableRowGroup: true,
        width: 100,
        resizable: true,
        hide: true
      },
      {
        headerName: 'TEAM',
        field: 'team_name',
        enableRowGroup: true,
        width: 100,
        resizable: true,
        hide: true
      },
      {
        headerName: 'REPORTING MANAGER',
        field: 'reporting_manager',
        enableRowGroup: true,
        hide: true,
        width: 100,
        resizable: true
      },
      {
        headerName: 'EMP CODE', field: 'emp_code',
        width: 100, menuTabs: []
      },
      {
        headerName: 'EMP NAME', field: 'name', enableRowGroup: true,
        width: 100
      },
      {
        headerName: 'EMP DOJ', field: 'doj', enableRowGroup: true,
        width: 100
      },
      {
        headerName: 'EMP DOE', field: 'doe', enableRowGroup: true,
        width: 100
      },
      {
        headerName: 'SALES AREA', field: 'sac',
        width: 100
      },
      {
        headerName: 'ROLE', field: 'role', enableRowGroup: true,
        width: 100
      },
      {
        headerName: 'P/L/H/A', field: 'attendance',
        maxWidth: 80, cellStyle: {textAlign: 'center'}
      },
      {
        headerName: 'ATT TYPE', field: 'att_type', enableRowGroup: true,
        width: 100
      },
      {
        headerName: 'LATE REASON', field: 'late_reason', enableRowGroup: true, hide: true,
        width: 100
      },
      {
        headerName: 'EOD TYPE', field: 'eod_type', enableRowGroup: true, hide: true,
        width: 100
      },
      {
        headerName: 'EOD REASON', field: 'eod_reason', enableRowGroup: true, hide: true,
        width: 100
      },
      {
        headerName: 'EOD REMARKS', field: 'eod_remarks', enableRowGroup: true, hide: true,
        width: 100
      },
      {
        headerName: 'DISTANCE', field: 'distance_travelled',
        maxWidth: 100, cellStyle: {textAlign: 'center'}
      },
      {
        headerName: 'IMAGE', field: 'store_fname',
        width: 150,
        valueGetter: this.generateImageUrl('store_fname'),
        cellRenderer: params => {
          if (params.data) {
            if (params.data.photo == null || params.data.photo === '') {
              return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
            } else {
              return '<img src="' + params.data.photo + '" style="padding:2px;margin-top:5px;width:100px;height:100px;"></img>';
            }
          }
        }
      },
      {
        headerName: 'latitude', field: 'latitude', width: 100, hide: true
      },
      {
        headerName: 'longitude', field: 'longitude', width: 100, hide: true
      },
      {
        headerName: 'ATT LOC',
        field: 'url',
        width: 80,
        resizable: true,
        cellStyle: {textAlign: 'center'},
        cellRenderer: params => {
          if (params.data) {
            if (params.data.longitude != null && params.data.latitude != null) {
              const url = 'https://maps.googleapis.com/maps/api/staticmap?center=' + params.data.latitude + ',' + params.data.longitude +
                '&zoom=19&size=400x400&markers=color:red|label:S|' + params.data.latitude + ',' + params.data.longitude + '&key=' + inThis.mapkey;
              return `<img src="` + url + `" style="padding:2px;margin-top:5px;width:100px;height:100px;"></img>`;
            } else {
              return '<div style="margin-top: 15px;"> ' +
                '<span>' +
                ' <i class="fas fa-map-marker-slash fa-2x" style="color:#D82128;' +
                'display: inline-block;\n' +
                '  vertical-align: middle;padding-bottom: 4px;cursor:pointer;"></i>' +
                '</span>' +
                '</div>';
            }

          }
        },
        valueGetter(params) {
          if (params.data) {
            if (params.data.longitude != null && params.data.latitude != null) {
              return 'http://maps.google.com/maps?q=' + params.data.latitude + ',' + params.data.longitude;
            } else {
              return 'NA';
            }
          }
        }
      },
      {
        headerName: 'LOCATION',
        field: 'location_name',
        width: 80,
        resizable: true,
        hide: true,
        cellStyle: {textAlign: 'center'},
        valueGetter(params) {
          if (params.data && params.data.location_name) {
            return params.data.location_name;
          } else {
            return 'NA';
          }
        }
      },
      {
        headerName: 'ATT TIME', field: 'att_time',
        maxWidth: 60, menuTabs: [],
        valueGetter: params => {
          if (params.data && params.data.att_time) {
            return datepipe.transform((new Date(params.data.att_time + ' UTC')).toString(), 'HH:mm');
          }
        }
      },
      {
        headerName: 'partner latitude', field: 'partner_latitude', width: 100, hide: true
      },
      {
        headerName: 'partner longitude', field: 'partner_longitude', width: 100, hide: true
      },
      {
        headerName: 'FIRST ACTIVITY', field: 'first_act',
        maxWidth: 70, menuTabs: [],
        valueGetter: params => {
          if (params.data && params.data.first_act) {
            return datepipe.transform((new Date(params.data.first_act)).toString(), 'HH:mm');
          }
        }
      },
      {
        headerName: 'LAST ACTIVITY', field: 'last_act',
        maxWidth: 70, menuTabs: [],
        valueGetter: params => {
          if (params.data && params.data.last_act) {
            return datepipe.transform((new Date(params.data.last_act)).toString(), 'HH:mm');
          }
        }
      },
      {
        headerName: 'EOD TIME', field: 'eod_time',
        maxWidth: 60, menuTabs: [],
        valueGetter: params => {
          if (params.data && params.data.eod_time) {
            return datepipe.transform((new Date(params.data.eod_time)).toString(), 'HH:mm');
          }
        }
      },
      {
        headerName: 'TIME IN MARKET', field: 'time_market',
        maxWidth: 70, menuTabs: [],
        valueGetter: params => {
          if (params.data) {
            if (params.data.att_time && params.data.eod_time) {

              const diff = new Date(params.data.eod_time).getTime() - new Date(params.data.att_time + ' UTC').getTime(); //in ms
              //return (diff / (1000 * 60)).toFixed(2);
              let msec = diff;
              const hh = Math.floor(msec / 1000 / 60 / 60);
              msec -= hh * 1000 * 60 * 60;
              const mm = Math.floor(msec / 1000 / 60);
              msec -= mm * 1000 * 60;
              const ss = Math.floor(msec / 1000);
              msec -= ss * 1000;

              return ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2);
            }
          }
        }
      },
      {
        headerName: 'TIME IN OUTLET', field: 'time_outlet',
        maxWidth: 70, menuTabs: [],
        valueGetter: params => {
          if (params.data) {
            if (params.data.first_act && params.data.last_act) {

              const diff = new Date(params.data.last_act).getTime() - new Date(params.data.first_act).getTime(); //in ms
              //return (diff / (1000 * 60)).toFixed(2);
              let msec = diff;
              const hh = Math.floor(msec / 1000 / 60 / 60);
              msec -= hh * 1000 * 60 * 60;
              const mm = Math.floor(msec / 1000 / 60);
              msec -= mm * 1000 * 60;
              const ss = Math.floor(msec / 1000);
              msec -= ss * 1000;

              return ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2);
            }
          }
        }
      },


      {
        headerName: 'STATUS',
        field: 'status',
        enableRowGroup: true,
        width: 100,
        resizable: true
      },
      {
        headerName: 'VERTICALS',
        field: 'vertical',
        enableRowGroup: true,
        width: 100,
        resizable: true,
        hide: true
      },
    ];
    this.getRowId = (params) => params.data.id;
  }

  postProcessData() {
    this.rowData = this.apiData;
    this.configureData();
  }

  async configureData() {
    let images = [];
    for (const key in this.rowData) {
      images = [];
      this.rowData[key].photo = null;
      if (this.rowData[key].store_fname != null) {
        images.push({store_fname: this.rowData[key].store_fname, container: 'hr_attendance'});
        //images.push({store_fname:  'cbd/cbdc5e57b977bc71e5011583f424e0aae23f0ecc4ebe7a47e258495ee3fb9aa3', container: 'expense_gallery'});
        const res = await this.apiService.fetchExpenseImage({
          access_token: this.userData.access_token,
          url: this.userData.url,
          data: images
        });
        if (res.hasOwnProperty('result') && res['result'].length > 0) {
          //console.log(res);
          this.rowData[key].photo = 'data:image/png;base64, ' + res['result'][0].bill_datas;
          this.gridApi.applyTransactionAsync({update: [this.rowData[key]]}, () => {
            // tslint:disable-next-line:no-non-null-assertion
            const row = this.gridApi.getDisplayedRowAtIndex(key);
            // tslint:disable-next-line:no-non-null-assertion
            this.gridApi.redrawRows({rowNodes: [row]});
          });
        }
      }
    }
  }

  onCellClicked(event) {
    const rowIndex = event.rowIndex;
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    const filteredRowData = this.gridApi.rowModel.rowsToDisplay;
    const activeItems = [];
    const ids = [];
    if (filteredRowData !== null && filteredRowData.length > 0) {
      filteredRowData.forEach(r => {
        activeItems.push({
          id: r.data.id,
          name: r.data.name
        });
        ids.push(r.data.id);
      });
      localStorage.setItem('activeID', event.data.id);
      localStorage.setItem('active_ids', JSON.stringify(ids));
      localStorage.setItem('activeItems', JSON.stringify(activeItems));
      localStorage.setItem('activeDate', this.userData.start_date);
      this.router.navigate([this.apiService.redirect_url + '/daily_user_tracking/details'], {
        relativeTo: this.route,
        queryParams: {id: rows[0].id}
      });
    }
  }
}
