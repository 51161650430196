import {Component, ElementRef, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {ApiService} from "../../_services/api.service";
import {SharedService} from "../../_services/shared_service";
import {DatePipe} from "@angular/common";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import QRCode from 'qrcode';
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-custom-print',
  templateUrl: './custom-print.component.html',
  styleUrls: ['./custom-print.component.scss']
})


export class CustomPrintComponent implements OnInit {
  @Input() order_detail: object;
  @Input() custom_template: object;
  @ViewChild('templ') templ: ElementRef;
  public template: any;
  public config: object;
  public payload: object;
  public math = Math;
  public company_logo = 'assets/images/sd-logo.png';
  public logo: SafeUrl;
  labelData: any = [];
  amount_in_words;
  amount_in_arabic;
  private a = ['', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ', 'Seven ', 'Eight ', 'Nine ', 'Ten ', 'Eleven ', 'Twelve ', 'Thirteen ',
    'Fourteen ', 'Fifteen ', 'Sixteen ', 'Seventeen ', 'Eighteen ', 'Nineteen '];
  private b = ['', '', 'Twenty ', 'Thirty ', 'Forty ', 'Fifty ', 'Sixty ', 'Seventy ', 'Eighty ', 'Ninety '];

  cgstHeader = "";
  cgstSuperHeader = "";
  sgstHeader = "";
  sgstSuperHeader = "";
  igstHeader = "";
  igstSuperHeader = "";
  cgstProdLine = "";
  sgstProdLine = "";
  igstProdLine = "";
  cgstPresent = false;
  sgstPresent = false;
  igstPresent = false;
  templateCheck = 'gst';
  subtotal;

  constructor(public apiService: ApiService,
              private datePipe: DatePipe,
              private domSanitizer: DomSanitizer) {
  }

  ngOnInit() {
    if (this.order_detail['date_due']) {
      this.order_detail['date_due'] = this.datePipe.transform((new Date(this.order_detail['date_due'] + ' UTC')).toString(), 'dd/MM/yyyy');
    }

    const custom_templates = JSON.parse(localStorage.getItem('custom_templates'));
    const updated = custom_templates.filter(elem => {
      return elem.type === 'Secondary Print PDF';
    });
    const appConfig = JSON.parse(localStorage.getItem('app')).config_json;
    if (appConfig && appConfig.hasOwnProperty('default_non_gst_print')) {
      const non_gst_template = updated.find(x => x.id === appConfig.default_non_gst_print);
      if (non_gst_template && this.custom_template['id'] === non_gst_template.id) {
        this.templateCheck = 'non_gst';
      } else {
        this.templateCheck = 'gst';
      }
    } else {
      this.templateCheck = 'gst';
    }
    this.config = this.custom_template['config_json'];
    this.template = decodeURIComponent(this.custom_template['template']);

    let columnCount = 10;
    const taxObj = this.order_detail['tax_notes'];
    if (taxObj !== null && taxObj !== undefined) {
      for (const [key, value] of Object.entries(taxObj)) {
        if (key === "CGST") {
          this.cgstHeader = "<TD class=\"tr10 td10\">Amt</TD>\n"
            + "<TD class=\"tr10 td10\">Rate</TD>\n";
          this.cgstSuperHeader = "<td class=\"tr10 td10\" colspan=\"2\">CGST</td>";

          this.cgstProdLine = "<TD class=\"tr12 td10\">$$product.cgst_amount</TD>\n" +
            "<TD class=\"tr12 td10\">$$product.cgst_rate</TD>";

          this.cgstPresent = true;
          columnCount = columnCount + 1;
        } else if (key === "SGST") {
          this.sgstHeader = "<TD class=\"tr10 td10\">Amt</TD>\n"
            + "<TD class=\"tr10 td10\">Rate</TD>\n";
          this.sgstSuperHeader = "<td class=\"tr10 td10\" colspan=\"2\">SGST</td>";

          this.sgstProdLine = "<TD class=\"tr12 td10\">$$product.sgst_amount</TD>\n" +
            "<TD class=\"tr12 td10\">$$product.sgst_rate</TD>";
          this.sgstPresent = true;
          columnCount = columnCount + 1;
        } else if (key === "IGST") {
          this.igstHeader = "<TD class=\"tr10 td10\">Amt</TD>\n"
            + "<TD class=\"tr10 td10\">Rate</TD>\n";
          this.igstSuperHeader = "<td class=\"tr10 td10\" colspan=\"2\">IGST</td>";

          this.igstProdLine = "<TD class=\"tr12 td10\">$$product.igst_amount</TD>\n" +
            "<TD class=\"tr12 td10\">$$product.igst_rate</TD>";
          this.igstPresent = true;
          columnCount = columnCount + 1;
        }
      }
    }
    this.template = this.template.replace("$$config.cgstheader", this.cgstHeader);
    this.template = this.template.replace("$$config.sgstheader", this.sgstHeader);
    this.template = this.template.replace("$$config.igstheader", this.igstHeader);
    this.template = this.template.replace("$$config.cgstSuperHeader", this.cgstSuperHeader);
    this.template = this.template.replace("$$config.sgstSuperHeader", this.sgstSuperHeader);
    this.template = this.template.replace("$$config.igstSuperHeader", this.igstSuperHeader);
    this.template = this.template.replace("$$config.columnCount", columnCount + "");

    this.getAmountInWords();
    this.makeQrCode();
    this.updateCustomerItems(this.template, 'template');
    this.updateSupplierItems(this.template, 'template');
    this.updateProductItems();
    this.updateOrderItems(this.template, 'template');
    this.updateTaxItems();
    this.template = this.template.replace('$$company_logo', this.order_detail['logo']);
    this.template = this.template.replace('SafeValue must use [property]=binding:', '');
    this.template = this.template.replace(' (see http://g.co/ng/security#xss)', '');
    this.template = this.template.replace('2px; width:auto', '2px; width:auto; min-width:60px;');
    //TODO: Logged in users label config and add null check
    if (this.order_detail['order_type'] === 'return' && this.order_detail['label_config']['CreditNote']) {
      this.template = this.template.replace('$$heading', this.order_detail['label_config']['CreditNote']);
    } else if (this.order_detail['order_type'] === 'damaged' && this.order_detail['label_config']['GoodsReplaceNote']) {
      this.template = this.template.replace('$$heading', this.order_detail['label_config']['GoodsReplaceNote']);
    } else {
      if (this.order_detail['label_config']['TaxInvoice']) {
        this.template = this.template.replace('$$heading', this.order_detail['label_config']['TaxInvoice']);
      } else {
        this.template = this.template.replace('$$heading', 'Tax Invoice');
      }
    }
    this.template = this.domSanitizer.bypassSecurityTrustHtml(this.template);
    console.log(this.template);
  }

  getAmountInWords() {
    if (this.order_detail['order_type'] === 'return') {
      this.amount_in_words = this.inWords(0);
      this.amount_in_arabic = this.GetArabicNumberInWords(0);
    } else {
      if (this.order_detail['amount_pay']) {
        this.order_detail['amount_pay'] = parseFloat(this.order_detail['amount_pay']).toFixed(2);
      }
      const amount = this.order_detail['amount_pay'].split('.');
      if (amount.length > 1 && parseInt(amount[1], 10) > 0) {
        this.amount_in_words = this.inWords(Math.abs(parseInt(amount[0], 10)));
        this.amount_in_words = this.order_detail['label_config']['PrimaryCurrency'] + ' ' +
          this.amount_in_words + ' And ' + this.order_detail['label_config']['SecondaryCurrency'] + ' ' +
          this.inWords(Math.abs(parseInt(amount[1], 10)));
        this.amount_in_arabic = this.GetArabicNumberInWords(Math.abs(parseInt(amount[0], 10)));
        this.amount_in_arabic = this.order_detail['label_config']['PrimaryCurrency'] + ' ' +
          this.amount_in_arabic + this.order_detail['label_config']['SecondaryCurrency'] + ' ' +
          this.GetArabicNumberInWords(Math.abs(parseInt(amount[1], 10)));
      } else {
        this.amount_in_words = this.order_detail['label_config']['PrimaryCurrency'] + ' ' +
          this.inWords(Math.abs(parseInt(amount[0], 10))) + ' Only';
        this.amount_in_arabic = this.order_detail['label_config']['PrimaryCurrency'] + ' ' +
          this.GetArabicNumberInWords(Math.abs(parseInt(amount[0], 10)));
      }
    }
  }

  GetArabicNumberInWords(numericValue) {

    const digits_length = (numericValue = numericValue.toString()).length;
    let i = 0;
    /*  ArrayList<String> str = new ArrayList<>();
      HashMap<Integer, String> words = new HashMap<>();*/
    const stringlist: any = [];
    const words = new Map();
    words.set(0, "");
    words.set(1, "واحد");
    words.set(2, "اثنين");
    words.set(3, "ثلاثة");
    words.set(4, "أربعة");
    words.set(5, "خمسة");
    words.set(6, "ستة");
    words.set(7, "سبعة");
    words.set(8, "ثمانية");
    words.set(9, "تسع");
    words.set(10, "عشرة");
    words.set(11, "أحد عشر");
    words.set(12, "اثني عشر");
    words.set(13, "ثلاثة عشر");
    words.set(14, "أربعة عشرة");
    words.set(15, "خمسة عشر");
    words.set(16, "السادس عشر");
    words.set(17, "سبعة عشر");
    words.set(18, "الثامنة عشر");
    words.set(19, "تسعة عشر");
    words.set(20, "عشرين");
    words.set(30, "ثلاثون");
    words.set(40, "أربعين");
    words.set(50, "خمسون");
    words.set(60, "ستون");
    words.set(70, "سبعون");
    words.set(80, "ثمانون");
    words.set(90, "تسعين");
    const digits: any = ["", "مائة", "ألف", "لكح", "الكرور عشرة ملا يين"];
    while (i < digits_length) {
      const divider = (i === 2) ? 10 : 100;
      const number = numericValue % divider;
      numericValue = numericValue / divider;
      if (numericValue.toString().includes('.')) {
        numericValue = parseInt(numericValue.toString().split(".")[0], 0);
      }
      i += divider === 10 ? 1 : 2;
      if (number > 0) {
        const counter = stringlist.length;
        const plural = (counter > 0 && number > 9) ? "s" : "";
        const tmp = (number < 21) ? words.get(number) + " " + digits[counter] + plural : words.get(Math.floor(number / 10) * 10) + " " + words.get((number % 10)) + " " + digits[counter] + plural;
        stringlist.push(tmp);
      } else {
        stringlist.push("");
      }
    }

    let valueInWords = "";
    stringlist.reverse();
    for (const rs of stringlist) {
      valueInWords = valueInWords + " " + rs;
    }
    return valueInWords;
  }

  /* inWords(nu) {
     const regex = /^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/;
     const getLT20 = (n) => this.a[Number(n)];
     const getGT20 = (n) => this.b[n[0]] + ' ' + this.a[n[1]];
     const num = Number(nu);
     if (isNaN(num)) return '';
     if (num === 0) return 'zero';

     const numStr = num.toString();
     if (numStr.length > 9) {
       throw new Error('overflow') // Does not support converting more than 9 digits yet
     }

     const [, n1, n2, n3, n4, n5] = ('000000000' + numStr).substr(-9).match(regex) // left pad zeros

     let str = '';
     str += n1 !== '00' ? (getLT20(n1) || getGT20(n1)) + 'crore ' : '';
     str += n2 !== '00' ? (getLT20(n2) || getGT20(n2)) + 'lakh ' : '';
     str += n3 !== '00' ? (getLT20(n3) || getGT20(n3)) + 'thousand ' : '';
     str += n4 !== '00' ? getLT20(n4) + 'hundred ' : '';
     str += n5 !== '0' && str !== '' ? 'and ' : '';
     str += n5 !== '0' ? (getLT20(n5) || getGT20(n5)) : '';

     return str.trim();
   }
 */
  inWords(num) {
    if ((num = num.toString()).length > 9) {
      return 'overflow';
    }
    const n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) {
      return;
    }
    let str = '';

    str += (n[1] !== '00') ? (this.a[Number(n[1])] || this.b[n[1][0]] + this.a[n[1][1]]) + 'Crore ' : '';
    str += (n[2] !== '00') ? (this.a[Number(n[2])] || this.b[n[2][0]] + this.a[n[2][1]]) + 'Lakh ' : '';
    str += (n[3] !== '00') ? (this.a[Number(n[3])] || this.b[n[3][0]] + this.a[n[3][1]]) + 'Thousand ' : '';
    str += (n[4] !== '0') ? (this.a[Number(n[4])] || this.b[n[4][0]]  + this.a[n[4][1]]) + 'Hundred ' : '';
    if (this.a[Number(n[5])]) {
      str += this.a[Number(n[5])];
    } else {
      if (this.a[n[5][1]] !== '') {
        str += this.b[n[5][0]] + ' ' + this.a[n[5][1]];
      } else {
        str += this.b[n[5][0]];
      }
    }
    return str;
  }

  makeQrCode() {
    const self = this;
    if (this.order_detail['has_einvoice']) {
      const opts = {
        margin: 10
      };
      if (this.order_detail['signed_qr']) {
        QRCode.toDataURL(this.order_detail['signed_qr'], opts, (err, url) => {
          self.order_detail['qr_image_code'] = url;
        });
        this.template = this.template.replace('$$qrcode', this.order_detail['qr_image_code']);
      }
    } else {
      if (!this.config.hasOwnProperty('qr_code') || this.config['qr_code'].data === '') {
        this.payload = {
          SellerName: this.order_detail['dc_name'],
          SellerTaxID: this.order_detail['dc_gstn'],
          SellerRegNo: this.order_detail['supplier_pan'],
          BuyerName: this.order_detail['partner'],
          BuyerTaxID: this.order_detail['partner_gstn'],
          BuyerRegNo: this.order_detail['pan'],
          DocNo: this.order_detail['invoice_no'],
          DocTyp: "INV",
          DocDt: this.order_detail['invoice_date'],
          DocTime: this.order_detail['order_date'],
          TotVal: this.order_detail['amount_total'],
          TaxAmt: this.order_detail['amount_tax'],
          Items: this.order_detail['total_qty'],
          Issuer: "salesdiary",
          CKey: this.order_detail['ckey'],
        };
      } else {
        const qr_config = this.config['qr_code'].data;
        this.payload = qr_config;
        this.updateOrderItems(this.payload, 'payload');
        this.updateCustomerItems(this.payload, 'payload');
        this.updateSupplierItems(this.payload, 'payload');
      }
      if (this.order_detail['order_date']) {
        this.payload['DocTime'] = this.order_detail['order_date'].split(' ')[1];
      }
      const opts = {
        margin: 10
      };
      QRCode.toDataURL(JSON.stringify(this.payload), opts, (err, url) => {
        self.order_detail['qr_image_code'] = url;
      });
      this.template = this.template.replace('$$qrcode', this.order_detail['qr_image_code']);
    }
    QRCode.toDataURL(this.order_detail['invoice_no'], (err, url) => {
      self.order_detail['qr_image_invoice'] = url;
    });
    this.template = this.template.replace('$$invoice_qr', this.order_detail['qr_image_invoice']);
  }

  updateOrderItems(template, key) {
    template = template.replace('$$order.reference', this.order_detail['name']);
    template = template.replace('$$order.date', this.order_detail['invoice_date']);
    if (this.order_detail['date_due']) {
      template = template.replace('$$order.payment_due_date', this.order_detail['date_due']);
    } else {
      template = template.replace('$$order.payment_due_date', '');
    }
    template = template.replace('$$order.salesman', this.order_detail['van']);
    template = template.replace('$$beat_plan.name', this.order_detail['beat_name']);
    template = template.replace('$$order.invoicenumber', this.order_detail['invoice_no']);
    if (this.order_detail.hasOwnProperty('irn') && this.order_detail['irn'] !== null) {
      template = template.replace('$$irn_number', this.order_detail['irn']);
    } else {
      template = template.replace('IRN : $$irn_number', '');
    }
    template = template.replace('$$order.items_count', this.order_detail['total_qty']);
    if (template.includes('amount_in_arabic')) {
      template = template.replace('$$order.amount_in_arabic', this.amount_in_arabic);
    } else {
      template = template.replace('$$order.amount_in_arabic', this.amount_in_words);
    }
    if (template.includes('amount_in_english')) {
      template = template.replace('$$order.amount_in_english', this.amount_in_words);
    }
    template = template.replace('$$order.subtotal', parseFloat(this.order_detail['amount_untaxed']).toFixed(2));
    template = template.replace('$$order.non_gst_subtotal', parseFloat(this.order_detail['amount_total']).toFixed(2));
    template = template.replace('$$order.tcs', this.order_detail['tcs']);
    template = template.replace('$$order.total', parseFloat(this.order_detail['amount_total']).toFixed(2));
    template = template.replace('$$order.payable', this.order_detail['amount_pay']);
    template = template.replace('$$order.discount', parseFloat(this.order_detail['discount']).toFixed(2));
    template = template.replace('$$disclaimer_text', this.order_detail['dist_print_text']);
    template = template.replace('$$po_number', this.order_detail['po']);
    template = template.replace('$$grn_number', this.order_detail['grn']);

    if (this.order_detail.hasOwnProperty('config_json') && this.order_detail['config_json']) {
      if (this.order_detail['config_json'].hasOwnProperty('totalVolume') && this.order_detail['config_json'].totalVolume) {
        this.order_detail['totalVolume'] = parseFloat(this.order_detail['config_json'].totalVolume).toFixed(2);
      }
      if (this.order_detail['config_json'].hasOwnProperty('totalWeight') && this.order_detail['config_json'].totalWeight) {
        this.order_detail['totalWeight'] = parseFloat(this.order_detail['config_json'].totalWeight).toFixed(2);
      }
    }

    if (template.includes('$$order.volume')) {
      if (this.order_detail.hasOwnProperty('totalVolume')) {
        template = template.replace('$$order.volume', this.order_detail['totalVolume']);
      } else {
        template = template.replace('$$order.volume', '');
      }
    }
    if (template.includes('$$order.weight')) {
      if (this.order_detail.hasOwnProperty('totalWeight')) {
        template = template.replace('$$order.weight', this.order_detail['totalWeight']);
      } else {
        template = template.replace('$$order.weight', '');
      }
    }
    if (this.order_detail.hasOwnProperty("payment_mode") && this.order_detail['payment_mode']) {
      if (this.order_detail['payment_mode'] === "credit") {
        template = template.replace("$$payment_mode", this.order_detail['label_config']['Credit']);
      } else if (this.order_detail['payment_mode'] === "cash") {
        template = template.replace("$$payment_mode", this.order_detail['label_config']['Cash']);
      } else if (this.order_detail['payment_mode'] === "cash_and_credit") {
        template = template.replace("$$payment_mode", this.order_detail['label_config']['CashAndCredit']);
      } else {
        template = template.replace("$$payment_mode", "");
      }
    } else {
      template = template.replace("$$payment_mode", "");
    }
    if (key === 'template') {
      this.template = template;
    } else {
      this.payload = template;
    }
  }

  updateCustomerItems(template, key) {
    template = template.replace('$$customer.name', this.order_detail['partner']);
    template = template.replace('$$customer.code', this.order_detail['code']);
    template = template.replace('$$customer.email', this.order_detail['partner_email']);
    template = template.replace('$$customer.phone', this.order_detail['partner_mobile']);
    template = template.replace('$$customer.street', this.order_detail['partner_street1']);
    template = template.replace('$$customer.street2', this.order_detail['partner_street2']);
    template = template.replace('$$customer.city', this.order_detail['city']);
    template = template.replace('$$customer.pin', this.order_detail['zip']);
    template = template.replace('$$customer.gst', this.order_detail['partner_gstn']);
    template = template.replace('$$customer.fssai', this.order_detail['partner_fssai']);
    template = template.replace('$$customer.pan', this.order_detail['pan']);
    template = template.replace('$$customer.upi', this.order_detail['partner_upi']);
    template = template.replace('$$retailer.code', this.order_detail['code']);
    const regexp1 = new RegExp(/##retailer.attribute.\w+/);
    let match = regexp1.exec(template);
    while (match !== null) {
      const substr = match[0].substring(match[0].indexOf('e.') + 2, match[0].length);
      if (this.order_detail.hasOwnProperty('partner_attribute_json') &&
        this.order_detail['partner_attribute_json'] !== null &&
        Object.keys(this.order_detail['partner_attribute_json']).includes(substr)) {
        template = template.replace(match[0], this.order_detail['partner_attribute_json'][substr]);
      } else {
        template = template.replace(match[0], '');
      }
      match = regexp1.exec(template);
    }
    if (key === 'template') {
      this.template = template;
    } else {
      this.payload = template;
    }
  }

  updateSupplierItems(template, key) {
    this.labelData = JSON.parse(localStorage.getItem('custom_labels'));
    let taxlabel = this.labelData.filter(tax => tax.label === 'TaxLabel').map(obj => obj.display);
    if (taxlabel !== null && taxlabel.length > 0) {
      taxlabel = taxlabel[0] + ' : ';
    } else {
      taxlabel = '';
    }
    template = template.replace('$$invoice_supplier.name', this.order_detail['dc_name']);
    template = template.replace('$$invoice_supplier.code', this.order_detail['dc_code']);
    template = template.replace('$$invoice_supplier.email', this.order_detail['dc_email']);
    template = template.replace('$$invoice_supplier.phone', this.order_detail['dc_mobile']);
    template = template.replace('$$invoice_supplier.street', this.order_detail['dc_street1']);
    template = template.replace('$$invoice_supplier.street2', this.order_detail['dc_street2']);
    template = template.replace('$$invoice_supplier.city', this.order_detail['supplier_city']);
    template = template.replace('$$invoice_supplier.pin', this.order_detail['supplier_zip']);
    template = template.replace('$$invoice_supplier.gst', taxlabel + this.order_detail['dc_gstn']);
    template = template.replace('$$invoice_supplier.fssai', this.order_detail['fssai']);
    template = template.replace('$$invoice_supplier.pan', this.order_detail['supplier_pan']);
    template = template.replace('$$invoice_supplier.upi', this.order_detail['supplier_upi']);
    const regexp1 = new RegExp(/##supplier.attribute.\w+/);
    let match = regexp1.exec(template);
    while (match !== null) {
      const substr = match[0].substring(match[0].indexOf('e.') + 2, match[0].length);
      if (this.order_detail.hasOwnProperty('supplier_attribute_json') &&
        this.order_detail['supplier_attribute_json'] !== null &&
        Object.keys(this.order_detail['supplier_attribute_json']).includes(substr)) {
        template = template.replace(match[0], this.order_detail['supplier_attribute_json'][substr]);
      } else {
        template = template.replace(match[0], '');
      }
      match = regexp1.exec(template);
    }
    if (key === 'template') {
      this.template = template;
    } else {
      this.payload = template;
    }
  }

  updateProductItems() {
    this.subtotal = 0.0;
    const prod_config = decodeURIComponent(this.config['product_line']);
    let finalProd = '';
    let prodlist = [];
    if (this.config.hasOwnProperty('group_by') && this.config['group_by'] === 'products') {
      const uq_products = [...new Set(this.order_detail['sale_items'].map(item => item.product_id))];
      if (uq_products) {
        for (const product of uq_products) {
          const products = this.order_detail['sale_items'].filter(x => x.product_id === product);
          const productrow = products[0];
          let abs_qty = 0;
          let amount_subtotal = 0;
          let amount_total = 0;
          let scheme_discount = 0;
          let cgst = 0;
          let sgst = 0;
          let igst = 0;
          let vat = 0;
          let cess = 0;
          for (const pdata of products) {
            abs_qty += parseInt(pdata['abs_qty'], 10);
            amount_subtotal += parseFloat(pdata['amount_subtotal']);
            amount_total += parseFloat(pdata['amount_total']);
            if (pdata.hasOwnProperty('scheme_discount') && pdata['scheme_discount'] !== null && pdata['scheme_discount'] !== undefined) {
              scheme_discount += parseFloat(pdata['scheme_discount']);

            }
            pdata['tax_notes'].forEach(elem => {
              if (elem.name.includes('CGST')) {
                const taxfound = productrow['tax_notes'].find(x => x.name.includes('CGST'));
                cgst += parseFloat(elem.tax);
              } else if (elem.name.includes('SGST')) {
                const taxfound = productrow['tax_notes'].find(x => x.name.includes('SGST'));
                sgst += parseFloat(elem.tax);
              } else if (elem.name.includes('IGST')) {
                const taxfound = productrow['tax_notes'].find(x => x.name.includes('IGST'));
                igst += parseFloat(elem.tax);
              } else if (elem.name.includes('VAT')) {
                const taxfound = productrow['tax_notes'].find(x => x.name.includes('VAT'));
                vat += parseFloat(elem.tax);
              } else if (elem.name.includes('CESS')) {
                const taxfound = productrow['tax_notes'].find(x => x.name.includes('CESS'));
                cess += parseFloat(elem.tax);
              }
            });
          }
          productrow['label'] = "[sale]";
          productrow['line_type'] = "normal";
          productrow['type'] = "sale";
          productrow['abs_qty'] = abs_qty;
          productrow['amount_subtotal'] = amount_subtotal;
          productrow['amount_total'] = amount_total;
          productrow['scheme_discount'] = scheme_discount;
          productrow['tax_notes'].forEach(elem => {
            if (elem.name.includes('CGST')) {
              elem.tax = cgst.toFixed(2);
            } else if (elem.name.includes('SGST')) {
              elem.tax = sgst.toFixed(2);
            } else if (elem.name.includes('IGST')) {
              elem.tax = igst.toFixed(2);
            } else if (elem.name.includes('VAT')) {
              elem.tax = vat.toFixed(2);
            } else if (elem.name.includes('CESS')) {
              elem.tax = cess.toFixed(2);
            }
          });
          if (productrow.hasOwnProperty('config_json') && productrow.config_json) {
            if (productrow.config_json.hasOwnProperty('totalVolume') && productrow.config_json.totalVolume) {
              productrow['totalVolume'] = parseFloat(productrow.config_json.totalVolume).toFixed(2);
            }
            if (productrow.config_json.hasOwnProperty('totalWeight') && productrow.config_json.totalWeight) {
              productrow['totalWeight'] = parseFloat(productrow.config_json.totalWeight).toFixed(2);
            }
          }

          prodlist.push(productrow);
        }
      } else {
        prodlist = this.order_detail['sale_items'];
      }
    } else {
      prodlist = this.order_detail['sale_items'];
    }
    let tot_scheme_discount = 0;

    prodlist.forEach(product => {
      finalProd = finalProd.concat(prod_config);
      finalProd = finalProd.replace("$$config.sgstProdLine", this.sgstProdLine);
      finalProd = finalProd.replace("$$config.cgstProdLine", this.cgstProdLine);
      finalProd = finalProd.replace("$$config.igstProdLine", this.igstProdLine);
      let product_name = product['product_name'];
      if (product.hasOwnProperty('type') && product.type !== null && product.type !== undefined) {
        if (product.type === 'free') {
          product_name = "<font color=#00A651><b>[FREE]</b></font>" + product['product_name'];
        } else if (product.type === 'promo') {
          product_name = "<font color=#00A651><b>[PROMO]</b></font> " + product['product_name'];
        }
      }
      finalProd = finalProd.replace('$$product.name', product_name);
      finalProd = finalProd.replace('$$product.mrp', product['mrp']);
      if (product.hasOwnProperty('hsn') && product.hsn !== null && product.hsn !== undefined) {
        finalProd = finalProd.replace('$$product.hsn', product['hsn']);
      } else {
        finalProd = finalProd.replace('$$product.hsn', '');
      }
      finalProd = finalProd.replace('$$product.qty', product['abs_qty']);
      finalProd = finalProd.replace('$$product.uom', product['product_uom']);
      if (finalProd.includes('reporting_uom')) {
        if (product.hasOwnProperty('reporting_uom') && product.reporting_uom  && product.reporting_uom !== "") {
          finalProd = finalProd.replace('$$product.reporting_uom', product['reporting_uom']);
        } else {
          finalProd = finalProd.replace('$$product.reporting_uom', "");
        }
      }
      if (product.hasOwnProperty('real_price') && product.real_price !== null && product.real_price !== undefined) {
        product['real_price'] = parseFloat(product['real_price']).toFixed(2);
      } else {
        product['real_price'] = 0;
      }

      finalProd = finalProd.replace('$$product.price_total', product['amount_total'].toFixed(2));
      let scheme_discount;
      if (product.hasOwnProperty('scheme_discount') && product.scheme_discount !== null && product.scheme_discount !== undefined) {
        tot_scheme_discount += parseFloat(product['scheme_discount']);
        scheme_discount = parseFloat(product['scheme_discount']).toFixed(2);
      } else {
        scheme_discount = 0;
      }
      finalProd = finalProd.replace('$$product.scheme_discount', scheme_discount);

      let line_tax_amount = 0;
      product['tax_notes'].forEach(elem => {
        if (elem.name.includes('CGST')) {
          finalProd = finalProd.replace('$$product.cgst_amount', parseFloat(elem.tax).toFixed(2));
          finalProd = finalProd.replace('$$product.cgst_rate', elem.rate + '%');
          finalProd = finalProd.replace('$$tax.cgst', (elem.tax / product['abs_qty']).toFixed(2));

          finalProd = finalProd.replace('$product.cgst_amount', parseFloat(elem.tax).toFixed(2));
          finalProd = finalProd.replace('$product.cgst_rate', elem.rate + '%');

          line_tax_amount += parseFloat(elem.tax);
        } else if (elem.name.includes('SGST')) {
          finalProd = finalProd.replace('$$product.sgst_amount', parseFloat(elem.tax).toFixed(2));
          finalProd = finalProd.replace('$$product.sgst_rate', elem.rate + '%');
          finalProd = finalProd.replace('$$tax.sgst', (elem.tax / product['abs_qty']).toFixed(2));

          finalProd = finalProd.replace('$product.sgst_amount', parseFloat(elem.tax).toFixed(2));
          finalProd = finalProd.replace('$product.sgst_rate', elem.rate + '%');

          line_tax_amount += parseFloat(elem.tax);
        } else if (elem.name.includes('IGST')) {
          finalProd = finalProd.replace('$$product.igst_amount', parseFloat(elem.tax).toFixed(2));
          finalProd = finalProd.replace('$$product.igst_rate', elem.rate + '%');
          finalProd = finalProd.replace('$$tax.isgst', (elem.tax / product['abs_qty']).toFixed(2));

          finalProd = finalProd.replace('$product.igst_amount', parseFloat(elem.tax).toFixed(2));
          finalProd = finalProd.replace('$product.igst_rate', elem.rate + '%');

          line_tax_amount += parseFloat(elem.tax);
        } else if (elem.name.includes('VAT')) {
          finalProd = finalProd.replace('$$product.vat_amount', parseFloat(elem.tax).toFixed(2));
          finalProd = finalProd.replace('$$product.vat_rate', elem.rate + '%');
          finalProd = finalProd.replace('$$tax.vat', (elem.tax / product['abs_qty']).toFixed(2));

          finalProd = finalProd.replace('$product.vat_amount', parseFloat(elem.tax).toFixed(2));
          finalProd = finalProd.replace('$product.vat_rate', elem.rate + '%');

          line_tax_amount += parseFloat(elem.tax);
        } else if (elem.name.includes('Cess')) {
          finalProd = finalProd.replace('$$product.cess_amount', parseFloat(elem.tax).toFixed(2));
          finalProd = finalProd.replace('$$product.cess_rate', elem.rate + '%');
          finalProd = finalProd.replace('$$tax.cess', (elem.tax / product['abs_qty']).toFixed(2));

          finalProd = finalProd.replace('$product.cess_amount', parseFloat(elem.tax).toFixed(2));
          finalProd = finalProd.replace('$product.cess_rate', elem.rate + '%');

          line_tax_amount += parseFloat(elem.tax);
        }
      });

      let sale_rate = 0;
      let unit_discount = 0;
      let unit_tax = 0;
      let discount_value = 0;
      sale_rate = product['amount_subtotal'] / product['abs_qty'];
      unit_discount = product['scheme_discount'] / product['abs_qty'];
      unit_tax = line_tax_amount / product['abs_qty'];
      discount_value = (parseFloat(product['real_price']) * product['abs_qty']) - parseFloat(product['amount_subtotal']);
      finalProd = finalProd.replace('$$product.price_unit', parseFloat(product['real_price']).toFixed(2));
      finalProd = finalProd.replace('$$product.price_subtotal', parseFloat(product['amount_subtotal']).toFixed(2));
      finalProd = finalProd.replace('$$product.price_non_gst_subtotal', parseFloat(product['amount_total']).toFixed(2));

      finalProd = finalProd.replace('$$product.sale_rate', sale_rate.toFixed(2));
      finalProd = finalProd.replace('$$product.unit_discount', unit_discount.toFixed(2));
      finalProd = finalProd.replace('$$product.unit_tax', unit_tax.toFixed(2));
      finalProd = finalProd.replace('$$product.discount_value', discount_value.toFixed(2));

      let basic_rate = product['real_price'];
      if (product.hasOwnProperty('taxmaster_id') && product.taxmaster_id !== null && product.taxmaster_id !== undefined) {
        if (product.hasOwnProperty('price_include') && product.price_include !== null && product.price_include !== undefined && product.price_include) {
          let price_tax;
          if (product.hasOwnProperty('tax_perc') && product.tax_perc !== null && product.tax_perc !== undefined) {
            try {
              price_tax = parseFloat(product['tax_perc']).toFixed(2);
            } catch (e) {
            }
          } else {
            price_tax = 0;
          }
          if (product.hasOwnProperty('tax_amount_type') && product.tax_amount_type !== null
            && product.tax_amount_type !== undefined && product.tax_amount_type === "fixed") {
            basic_rate = basic_rate - price_tax;
          } else {
            basic_rate = basic_rate / (1 + (price_tax / 100));
          }
        }
      }

      if (basic_rate !== null && basic_rate !== undefined) {
        basic_rate = parseFloat(basic_rate).toFixed(2);
      }
      finalProd = finalProd.replace('$$product.basic_rate', basic_rate);
      finalProd = finalProd.replace('$$product.non_gst_basic_rate', product['real_price']);

      if (finalProd.includes('$$product.cgst_amount')) {
        finalProd = finalProd.replace('$$product.cgst_amount', '0.0');
      }
      if (finalProd.includes('$$product.cgst_rate')) {
        finalProd = finalProd.replace('$$product.cgst_rate', '');
      }
      if (finalProd.includes('$$product.sgst_amount')) {
        finalProd = finalProd.replace('$$product.sgst_amount', '0.0');
      }
      if (finalProd.includes('$$product.sgst_rate')) {
        finalProd = finalProd.replace('$$product.sgst_rate', '');
      }
      if (finalProd.includes('$$product.igst_amount')) {
        finalProd = finalProd.replace('$$product.igst_amount', '0.0');
      }
      if (finalProd.includes('$$product.igst_rate')) {
        finalProd = finalProd.replace('$$product.igst_rate', '');
      }
      if (finalProd.includes('$$product.vat_amount')) {
        finalProd = finalProd.replace('$$product.vat_amount', '0.0');
      }
      if (finalProd.includes('$$product.vat_rate')) {
        finalProd = finalProd.replace('$$product.vat_rate', '');
      }
      if (finalProd.includes('$$product.cess_amount')) {
        finalProd = finalProd.replace('$$product.cess_amount', '0.0');
      }
      if (finalProd.includes('$$product.cess_rate')) {
        finalProd = finalProd.replace('$$product.cess_rate', '');
      }
      if (finalProd.includes('$$product.scheme_campaign_type')) {
        if (product.hasOwnProperty('campaign_type') && product.campaign_type) {
          finalProd = finalProd.replace('$$product.scheme_campaign_type', product.campaign_type);
        } else {
          finalProd = finalProd.replace('$$product.scheme_campaign_type', '');
        }
      }

      const regexp1 = new RegExp(/##product.attribute.\w+/);
      let match = regexp1.exec(finalProd);
      while (match !== null) {
        const substr = match[0].substring(match[0].indexOf('e.') + 2, match[0].length);
        if (product.hasOwnProperty('attribute_json') &&
          product['attribute_json'] !== null &&
          Object.keys(product['attribute_json']).includes(substr)) {
          finalProd = finalProd.replace(match[0], product['attribute_json'][substr]);
        } else {
          finalProd = finalProd.replace(match[0], '');
        }
        match = regexp1.exec(finalProd);
      }
      if (finalProd.includes('$$product.volume')) {
        if (product.hasOwnProperty('totalVolume')) {
          finalProd = finalProd.replace('$$product.volume', product['totalVolume']);
        } else {
          finalProd = finalProd.replace('$$product.volume', '');
        }
      }
      if (finalProd.includes('$$product.weight')) {
        if (product.hasOwnProperty('totalWeight')) {
          finalProd = finalProd.replace('$$product.weight', product['totalWeight']);
        } else {
          finalProd = finalProd.replace('$$product.weight', '');
        }
      }
      if (finalProd.includes('$$product.batch_details')) {
        if (product?.config_json?.batch_json) {
          let batch_line;
          let batch_config = decodeURIComponent(this.config['batch_lines']);
          if (product?.config_json?.batch_json.length === 1) {
            if (batch_config.includes("$$batchline.qty")) {
              batch_config = batch_config.replace("$$batchline.qty", "");
            }
            batch_line = this.updateBatchItems(batch_config, product?.config_json?.batch_json);
          } else {
            batch_line = this.updateBatchItems(batch_config, product?.config_json?.batch_json);
          }
          finalProd = finalProd.replace('$$product.batch_details', batch_line);
        } else {
          finalProd = finalProd.replace('$$product.batch_details', "");
        }
      }
    });
    if (tot_scheme_discount && tot_scheme_discount > 0) {
      this.order_detail['tot_scheme_discount'] = tot_scheme_discount.toFixed(2);
    } else {
      this.order_detail['tot_scheme_discount'] = 0;
    }
    if (this.template.includes('line_scheme_discount')) {
      this.template = this.template.replace('$$order.line_scheme_discount', this.order_detail['tot_scheme_discount']);
    }
    this.template = this.template.replace('$$config.product_lines', finalProd);
  }

  updateBatchItems(batch_config, batch_json) {
    let batch_line = "";
    batch_json.forEach(batch => {
      let temp_batch_line = batch_config;
      if (temp_batch_line.includes("$$batchline.qty")) {
        if (batch?.qty) {
          temp_batch_line = temp_batch_line.replace('$$batchline.qty', "Qty : " + batch.qty);
        } else {
          temp_batch_line = temp_batch_line.replace('$$batchline.qty', "");
        }
      }
      if (temp_batch_line.includes("$$batchline.batch_date")) {
        if (batch?.batch_date) {
          temp_batch_line = temp_batch_line.replace('$$batchline.batch_date', batch.batch_date);
        } else {
          temp_batch_line = temp_batch_line.replace('$$batchline.batch_date', "");
        }
      }
      if (temp_batch_line.includes("$$batchline.expiry")) {
        if (batch?.expiry_date) {
          temp_batch_line = temp_batch_line.replace('$$batchline.expiry', batch.expiry_date);
        } else {
          temp_batch_line = temp_batch_line.replace('$$batchline.expiry', "");
        }
      }
      if (temp_batch_line.includes("$$batchline.batch_code")) {
        if (batch?.code) {
          temp_batch_line = temp_batch_line.replace('$$batchline.batch_code', batch.code);
        } else {
          temp_batch_line = temp_batch_line.replace('$$batchline.batch_code', "");
        }
      }
      batch_line += temp_batch_line;
    });
    return batch_line;
  }

  updateTaxItems() {
    let tax_config = decodeURIComponent(this.config['tax_lines']);
    tax_config = tax_config.replace(/\+/g, ' ');
    let finalTax = '';
    this.order_detail['tax_breakup'].forEach(tax => {
      finalTax = finalTax.concat(tax_config);
      finalTax = finalTax.replace('$$taxline.name', tax['name']);
      finalTax = finalTax.replace('$$taxline.value', parseFloat(String(Math.abs(tax.tax))).toFixed(2));
    });
    this.template = this.template.replace('$$config.tax_lines', finalTax);
  }

}
