<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper">
      <div id="overlay"></div>
      <div>
        <app-page-loader [show]="!pageReady"></app-page-loader>
      </div>
      <div style="margin-right: 10px;">
        <div class="col-md-12 cardbox" style="width: 100%">
          <div class="search-list">
            <div class="row" style="height: 33px;">
              <div *ngIf="!editMode && activeObject['status'] === 'new'" class="button-row">
                <button
                  class="btn blue-button"
                  (click)="action_confirm_meet()"
                >CONFIRM MEET
                </button>
              </div>
              <div
                *ngIf="!editMode && activeObject['status'] === 'completed' && feature_meet_approve && show_approve_button"
                class="button-row">
                <button
                  class="btnsmall confirm-green-btn"
                  (click)="openApproveRejectPopup('approved')"
                >APPROVE EXPENSE
                </button>
              </div>
              <div
                *ngIf="!editMode && ((activeObject['status'] === 'completed' || activeObject['status'] === 'processing') && feature_meet_approve && show_approve_button)"
                class="button-row" style="margin-left: 10px;">
                <button
                  class="btnsmall reject-btn"
                  (click)="openApproveRejectPopup('rejected')"
                >REJECT EXPENSE
                </button>
              </div>
              <div
                *ngIf="!editMode && (activeObject['status'] === 'new' || activeObject['status'] === 'confirmed')"
                class="button-row">
                <button
                  class="btn grey-button"
                  (click)="action_cancel()"
                >CANCEL
                </button>
              </div>


              <div *ngIf="!newMode && !editMode && listLength > 0" style="position: absolute;right: 40px;">
                <div class="button-row centeralign">
                  <div style="display: inline-block; margin-right: 20px; padding: 6px 20px;"
                       class="btnsmall light-blue-btn">
                          <span
                            style="height: 8px;width: 8px;margin-right: 5px;background-color: #379862;border-radius: 50%; display: inline-block;"></span><span
                    style="color: #2D2D2D; text-transform: capitalize;">{{ activeObject['status'] }}</span>
                  </div>
                  <div style="display: inline-block; ">
                         <span (click)="movePrevious()"><i
                           [ngClass]="{'disabledspan': rowIndex === 0,
                         'enabledspan': rowIndex >0}"
                           class="fal fa-arrow-circle-left fa-2x"></i></span>
                    <span
                      style="margin-left: 5px; margin-right: 5px; margin-bottom: 2px;font-size: 12px;">{{ rowIndex + 1 }}
                      /{{ listLength }}</span>
                    <span (click)="moveNext()"><i
                      [ngClass]="{'disabledspan': rowIndex === listLength-1,
                         'enabledspan': rowIndex < listLength-1}"
                      class="fal fa-arrow-circle-right fa-2x"></i></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 pt-3 pad0" *ngIf="newMode">
          </div>

          <div class="col-md-12 pt-3 pad0" *ngIf="!newMode">
            <div class="col-md-2 pl-0 pr-30">
              <label class="detaillabel2">Type</label>
              <p class="detailvaluebold">{{ activeObject['type'] }}</p>
            </div>

            <div class="col-md-2 pl-0 pr-30">
              <label class="detaillabel2">Salesman</label>
              <p class="detailvaluebold">{{ activeObject['salesman'] }}</p>
              <p class="detailvaluedesc">Code : {{activeObject['emp_code']}}</p>
              <p class="detailvaluedesc">Territory : {{activeObject['territory_name']}}</p>
            </div>

            <div class="col-md-2 pl-0 pr-30">
              <label class="detaillabel2">Planned</label>
              <p class="detailvaluebold"
                 *ngIf="activeObject['planned_date']">{{ activeObject['planned_date'] + 'Z' | date:'yyyy-MM-dd' }}</p>
            </div>

            <div class="col-md-2 pl-0 pr-30">
              <label class="detaillabel2">Executed</label>
              <p class="detailvaluebold"
                 *ngIf="activeObject['start_time']">{{ activeObject['start_time'] + 'Z' | date:'yyyy-MM-dd' }}</p>
              <div *ngIf="same_day_start_n_end">
                <p class="detailvaluedesc" *ngIf="activeObject['start_time']">Start Time : {{activeObject['start_time'] + 'Z' | date:'HH:mm' }}</p>
                <p class="detailvaluedesc" *ngIf="activeObject['end_time']">End Time : {{activeObject['end_time']+ 'Z' | date:'HH:mm' }}</p>
              </div>
              <div *ngIf="!same_day_start_n_end">
                <p class="detailvaluedesc" *ngIf="activeObject['start_time']">Start Time : {{activeObject['start_time'] + 'Z' | date:'yyyy-MM-dd HH:mm' }}</p>
                <p class="detailvaluedesc" *ngIf="activeObject['end_time']">End Time : {{activeObject['end_time'] + 'Z' | date:'yyyy-MM-dd HH:mm' }}</p>
              </div>
            </div>

            <div class="col-md-2 pl-0 pr-30">
              <label class="detaillabel2">Budget</label>
              <p class="detailvaluebold"
                 *ngIf="activeObject['config_json'] && activeObject['config_json'].hasOwnProperty('t_bud')">{{ activeObject['config_json']['t_bud'] }}</p>
            </div>

            <div class="col-md-2 pl-0 pr-30">
              <label class="detaillabel2">Expenses</label>
              <p class="detailvaluebold"
                 *ngIf="activeObject['config_json'] && activeObject['config_json'].hasOwnProperty('t_exp') && activeObject['config_json'].hasOwnProperty('t_var')">{{ activeObject['config_json']['t_exp'] }}
                ( <span class="value-data"
                        [ngClass]="{'high': activeObject['config_json']['t_var'] > 0, 'low': activeObject['config_json']['t_var'] < 0}">{{ activeObject['config_json']['t_var'].toFixed(2) }}
                  %</span> )</p>
              <p class="detailvaluebold"
                 *ngIf="activeObject['config_json'] && activeObject['config_json'].hasOwnProperty('t_exp') && !activeObject['config_json'].hasOwnProperty('t_var')">{{ activeObject['config_json']['t_exp'] }}</p>
            </div>
          </div>
        </div>
        <div class="col-md-12 cardbox mt-4" *ngIf="(photos && photos.length > 0) || ( activeObject['config_json'] && activeObject['config_json']['expense_approval'] && activeObject['config_json']['expense_approval']['status'])">
          <div class="row centeralign" style="cursor: pointer" (click)="detailsClick()">
            <span style="color: #2D2D2D; font-size: 14px;font-weight: 600;">Additional Details</span>
            <img *ngIf="!showDetails" style="position: absolute; right: 30px;height: 8px;" src="/assets/images/down_arrow_black.svg"/>
            <img *ngIf="showDetails" style="position: absolute; right: 30px;height: 8px;" src="/assets/images/up_arrow.svg"/>
          </div>
          <div *ngIf="showDetails" class="row centeralign mt-3">
            <div *ngIf="activeObject['config_json'] && activeObject['config_json']['expense_approval'] && activeObject['config_json']['expense_approval']['status']">
              <span class="detaillabel1 mr-2">Expense {{activeObject['config_json']['expense_approval']['status']}} By: </span>
              <span class="detailvaluebold">{{activeObject['config_json']['expense_approval']['apr_by']}}</span>

              <span class="greyindicator" style="margin-left: 30px;"></span>
              <span class="detaillabel1 mr-2">Role</span>
              <span class="detailvaluebold">{{activeObject['config_json']['expense_approval']['role']}}</span>

              <span class="greyindicator" style="margin-left: 30px;"></span>
              <span class="detaillabel1 mr-2">On: </span>
              <span class="detailvaluebold" *ngIf="activeObject['config_json']['expense_approval']['apr_on']">{{activeObject['config_json']['expense_approval']['apr_on'] + 'Z' | date:'yyyy-MM-dd HH:mm'}}</span>

              <span class="greyindicator" style="margin-left: 30px;"></span>
              <span class="detaillabel1 mr-2">Comments</span>
              <span class="detailvaluebold">{{activeObject['config_json']['expense_approval']['comments']}}</span>
            </div>
          </div>

          <div  *ngIf="showDetails && photos && photos.length > 0" class="mt-3 gallerycardgrid">
            <ng-container *ngFor="let data of photos; let i = index">
              <img style="width:150px; cursor: pointer" [src]="data.photo"
                   (click)="showImagePopup(data.photo)"/>
            </ng-container>
          </div>

        </div>
        <div class="col-md-12 cardbox mt-4">
          <div class="col-md-12 pad0">
            <div class="search-list">
              <div class="col-md-6 col-12 nopadding pl-0" style="display: inline-block">
                <mat-tab-group (selectedTabChange)="changeActiveTab($event)">
                  <mat-tab label="Attendees">
                  </mat-tab>
                  <mat-tab label="Budget">
                  </mat-tab>
                  <mat-tab label="Expenses">
                  </mat-tab>
                </mat-tab-group>
              </div>
              <div class="col-md-6 col-12 pad0 text-right">
                <div class="filters">
                  <ul class="list-unstyled">
                    <li class="margLR" style="margin-left: 10px;">
                      <div class="search" style="display: inline-block;">
                        <span class="search-control"><img src="assets/searchicon.png" alt="Search"/></span>
                        <input id="filter-text-box1" type="text" (input)="quickSearch()"
                               class="search-control-area"
                               alt="Search"
                               placeholder="Search">
                      </div>
                    </li>
                    <li *ngIf="activetab==='Summary'" class="margLR" style="margin-left: 10px;">
                      <app-excelicon [gridOption]="attendees_gridOptions" [title]="'Summary Report'"></app-excelicon>
                    </li>
                    <li *ngIf="activetab==='Sale'" class="margLR" style="margin-left: 10px;">
                      <app-excelicon [gridOption]="budget_gridOptions" [title]="'Sale Report'"></app-excelicon>
                    </li>
                    <li *ngIf="activetab==='Expense'" class="margLR" style="margin-left: 10px;">
                      <app-excelicon [gridOption]="expense_gridOptions" [title]="'Expense Report'"></app-excelicon>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div *ngIf="activetab==='Attendees'" class="col-md-12 col-12 nopadding pl-0" style="margin: 10px;">
              <div class="col-md-12 pad0" style="padding-right: 10px;">
                <div class="eod-list-card">
                  <div id="summarygrid" class="eod-list-card-desc" style="width:100%">
                    <ag-grid-angular style="height: calc(70vh - 160px);" *ngIf="dataLoaded"
                                     id="myGrid2" class="ag-theme-alpine"
                                     [columnDefs]="attendees_columnDefs"
                                     [gridOptions]="attendees_gridOptions"
                                     (gridReady)="attendeesOnGridReady($event)"
                                     [context]="attendees_gridContext"
                    >
                    </ag-grid-angular>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="activetab==='Budget'" class="col-md-12 col-12 nopadding pl-0" style="margin: 10px;">
              <div class="col-md-12 pad0" style="padding-right: 10px;">
                <div class="eod-list-card">
                  <div id="salegrid" class="eod-list-card-desc" style="width:100%">
                    <ag-grid-angular style="width: 100%; height: calc(70vh - 160px);" *ngIf="dataLoaded"
                                     id="myGrid2" class="ag-theme-alpine"
                                     [columnDefs]="budget_columnDefs"
                                     [gridOptions]="budget_gridOptions"
                                     (gridReady)="saleOnGridReady($event)"
                                     (cellClicked)="saleOnCellClicked($event)"
                                     [getRowId]="getRowId"
                                     [context]="budget_gridContext"
                    >
                    </ag-grid-angular>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="activetab==='Expenses'" class="col-md-12 col-12 nopadding pl-0" style="margin: 10px;">
              <div class="col-md-12 pad0" style="padding-right: 10px;">
                <div class="eod-list-card">
                  <div id="expensegrid" class="eod-list-card-desc" style="width:100%">
                    <ag-grid-angular style="width: 100%; height: calc(70vh - 160px);" *ngIf="dataLoaded"
                                     id="myGrid2" class="ag-theme-alpine"
                                     [columnDefs]="expense_columnDefs"
                                     [gridOptions]="expense_gridOptions"
                                     (gridReady)="expenseOnGridReady($event)"
                                     (cellClicked)="onExpenseCellClicked($event)"
                                     [getRowId]="getRowId"
                                     [context]="expense_gridContext"
                    >
                    </ag-grid-angular>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

