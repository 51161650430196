import {ITooltipAngularComp} from "@ag-grid-community/angular";
import {ITooltipParams} from "@ag-grid-enterprise/all-modules";
import {Component} from "@angular/core";

@Component({
  selector: 'app-loadouttooltip-component',
  template: `
    <div *ngIf="enable" class="custom-tooltip" [style.background-color]="color">
      <table style="margin: 5px;  border-spacing: 0 10px;table-layout: fixed;width: 100%;">
        <tr>
          <!--<td class="num_label">User Request</td>-->
          <td class="num_label padL padR">Total Loadout</td>
          <td class="num_label padL padR ">Pre Order</td>
        </tr>
        <tr>
         <!-- <td class="num_label">{{request}}</td>-->
          <td class="num_label value padL padR">{{loadout}}</td>
          <td class="num_label value padL padR ">{{preorder}}</td>
        </tr>
      </table>
     </div>`,
  styles: [
    `
      :host {
        width: 200px;
        height: 90px;
        pointer-events: none;
        transition: opacity 1s;
      }

      :host.ag-tooltip-hiding {
        opacity: 0;
      }

      .custom-tooltip {
        border: 1px solid #C4C4C4;
        border-radius: 5px;
      }

      .highlightred {
        color: indianred;
        margin-left: 5%;
        font-weight: bold;
        padding-left: 10px;
        padding-right: 10px;
        border-right: #C4C4C4 1px solid;
      }

      .highlightgreen {
        color: #379862;
        margin-left: 5%;
        font-weight: bold;
        padding-left: 10px;
        padding-right: 10px;
        border-right: #C4C4C4 1px solid;
      }

      .highlightrednoborder {
        color: indianred;
        margin-left: 5%;
        font-weight: bold;
        padding-left: 10px;
        padding-right: 10px;
      }

      .highlightgreennoborder {
        color: #379862;
        margin-left: 5%;
        font-weight: bold;
        padding-left: 10px;
        padding-right: 10px;
      }

      .nonhighlight {
        color: #5D5D5D;
        margin-left: 5%;
        font-weight: bold;
        padding-left: 10px;
        padding-right: 10px;
        border-right: #C4C4C4 1px solid;
      }

      .nonhighlightnoborder {
        color: #5D5D5D;
        margin-left: 5%;
        font-weight: bold;
        padding-left: 10px;
        padding-right: 10px;
      }

      .padR {
        padding-right: 10px;
      }

      .padL {
        padding-left: 10px;
      }

      .mrgR {
        border-right: #C4C4C4 1px solid;
      }

      .mrgD {
        border-bottom: #C4C4C4 1px solid;
      }

      .num {
        color: #5D5D5D;
        margin-left: 5%;
        font-weight: bold;
      }

      .num_label {
        width: 100%;
        color: darkblue;
        margin-left: 5px;
        margin-right: 5px;
      }

      .value {
        color: #1D1D1D;
        font-weight: 600;
      }

      .cGrey {
        color: lightgrey;
      }

      .cRed {
        color: indianred;
      }
    `,
  ],
})
export class LoadouttooltipComponent implements ITooltipAngularComp {
  private params!: { color: string } & ITooltipParams;
  public enable = true;
  public color!: string;
  request = 0;
  loadout = 0;
  preorder = 0;

  agInit(params: { color: string } & ITooltipParams): void {
    console.log(params);
    this.params = params;
    this.color = this.params.color || 'white';
    if (this.params?.data?.preorder) {
      this.preorder = this.params.data.preorder;
    }
    if (this.params?.data?.loadout) {
      this.loadout = this.params.data.loadout;
    }
  }
}
